import React from "react";
import clsx from "clsx";
import { overrideTailwindClasses } from "tailwind-override";
import type { LinkProps } from "./link";
import { Link } from "~/components/ui/link";
import { Paragraph } from "./typography";
import type { Cta } from "~/types";
import { ArrowLink } from "./arrow-button";
import { IconLink } from "./icon-link";

export interface ButtonProps {
	variant?: "primary" | "secondary" | "ghost" | "secondaryFill";
	size?: "default" | "dense";
	children: React.ReactNode;
	className?: string;
	iconLeft?: JSX.Element | null;
	iconRight?: JSX.Element | null;
	fullWidth?: boolean;
}

export interface CtaButtonGroupProps {
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
	tertiaryCTA?: Cta;
	size?: "default" | "dense";
}

/* Set min width/height to ensure WCAG 2.5.5 */
const commonStyling =
	"inline-flex items-center justify-center transition-colors duration-200 ease-in-out rounded-sm border border-transparent text-center font-medium disabled:cursor-not-allowed focus-visible:outline-blue-40";

const btnPrimaryStyling = "bg-button-primary text-button-primary no-underline";

const btnSecondaryFillStyling =
	"bg-white text-black border-button-secondary-fill";

const btnSecondaryStyling =
	"bg-button-secondary text-button-secondary border-button-secondary no-underline disabled:outline-grey-10";

const btnGhostStyling =
	"bg-transparent text-button-secondary underline-offset-8 transition-colors duration-200 ease-in-out hover:underline no-underline";

const SIZES = {
	dense: "py-2 px-4 min-h-[32px]",
	default: "py-3 px-5 min-h-[48px]",
};

export function Button({
	children,
	variant = "primary",
	size = "default",
	fullWidth = false,
	iconLeft,
	iconRight,
	className,
	...rest
}: ButtonProps & JSX.IntrinsicElements["button"]) {
	return (
		<button
			{...rest}
			className={overrideTailwindClasses(
				clsx(
					"[&>*]:pointer-events-none",
					commonStyling,
					{
						[btnPrimaryStyling]: variant === "primary",
						[btnSecondaryStyling]: variant === "secondary",
						[btnGhostStyling]: variant === "ghost",
					},
					{
						[SIZES.default]: size === "default",
						[SIZES.dense]: size === "dense",
					},
					{
						"w-full": fullWidth,
					},
					className
				)
			)}
		>
			<div className="inline-flex items-center justify-center gap-2">
				{iconLeft ? iconLeft : null}
				<Paragraph
					color="current"
					size={size !== "dense" ? "button" : "button-small"}
				>
					{children}
				</Paragraph>
				{iconRight ? iconRight : null}
			</div>
		</button>
	);
}

export function ButtonLink({
	children,
	variant = "primary",
	size = "default",
	fullWidth = false,
	iconLeft,
	iconRight,
	prefetch = "intent",
	to,
	className,
	...rest
}: LinkProps & ButtonProps) {
	return (
		<Link
			prefetch={prefetch}
			to={to}
			{...rest}
			className={overrideTailwindClasses(
				clsx(
					commonStyling,
					{
						[btnPrimaryStyling]: variant === "primary",
						[btnSecondaryStyling]: variant === "secondary",
						[btnGhostStyling]: variant === "ghost",
						[btnSecondaryFillStyling]: variant === "secondaryFill",
					},
					{
						[SIZES.default]: size === "default",
						[SIZES.dense]: size === "dense",
					},
					{
						"w-full": fullWidth,
					},
					className
				)
			)}
		>
			<div className="inline-flex items-center justify-center gap-3">
				{iconLeft ? iconLeft : null}
				<Paragraph
					color="current"
					size={size !== "dense" ? "button" : "button-small"}
				>
					{children}
				</Paragraph>
				{iconRight ? iconRight : null}
			</div>
		</Link>
	);
}

/**
 * A button that looks like a link
 */
export function LinkButton({
	className,
	...buttonProps
}: { underlined?: boolean } & JSX.IntrinsicElements["button"]) {
	return (
		<button
			{...buttonProps}
			className={clsx(
				className,
				className?.includes("block") ? "" : "inline-block",
				"text-link"
			)}
		/>
	);
}

export function CtaButtonGroup({
	primaryCTA,
	secondaryCTA,
	tertiaryCTA,
	size = "default",
}: CtaButtonGroupProps) {
	return (
		<>
			{primaryCTA && primaryCTA.title && primaryCTA.url ? (
				<div className="flex flex-col gap-5">
					<ButtonLink
						reloadDocument={primaryCTA.reloadDocument}
						variant="primary"
						fullWidth
						to={primaryCTA.url}
						trackingPosition={primaryCTA.trackingPosition}
						size={size}
						iconLeft={primaryCTA.iconLeft}
					>
						{primaryCTA.title}
					</ButtonLink>
					{primaryCTA.caption ? (
						<Paragraph
							size="body-xsmall"
							className="text-center dark:text-white md:mx-auto md:max-w-[280px]"
						>
							{primaryCTA.caption}
						</Paragraph>
					) : null}
				</div>
			) : null}
			{secondaryCTA && secondaryCTA.title && secondaryCTA.url ? (
				<div className="flex flex-col gap-5">
					<ButtonLink
						reloadDocument={secondaryCTA.reloadDocument}
						variant="secondary"
						fullWidth
						size={size}
						to={secondaryCTA.url}
						trackingPosition={secondaryCTA.trackingPosition}
						iconLeft={secondaryCTA.iconLeft}
					>
						{secondaryCTA.title}
					</ButtonLink>
					{secondaryCTA.caption ? (
						<Paragraph
							size="body-xsmall"
							className="text-center dark:text-white md:max-w-[280px]"
						>
							{secondaryCTA.caption}
						</Paragraph>
					) : null}
				</div>
			) : null}
			{tertiaryCTA && tertiaryCTA.title && tertiaryCTA.url ? (
				<div className="flex flex-col gap-5 pt-4">
					{tertiaryCTA.iconLeft ? (
						<IconLink
							className="font-medium"
							to={tertiaryCTA.url}
							iconLeft={tertiaryCTA.iconLeft}
							trackingPosition={tertiaryCTA.trackingPosition}
						>
							{tertiaryCTA.title}
						</IconLink>
					) : (
						<ArrowLink
							to={tertiaryCTA.url}
							trackingPosition={tertiaryCTA.trackingPosition}
						>
							{tertiaryCTA.title}
						</ArrowLink>
					)}
				</div>
			) : null}
		</>
	);
}
